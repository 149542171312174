import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';


import Typist from 'react-typist';
// @ts-ignore
import Roll from 'react-reveal/Roll';
// @ts-ignore
import Fade from 'react-reveal/Fade';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useComponentMounted } from '../hooks/componentMounted';

// interface IProps {
//   data: {
//     site: {
//       siteMetadata: {
//         title: string;
//       };
//     };
//   };
// }

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-tilted.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { componentMounted } = useComponentMounted();

  return (
    <Layout>
      <SEO title="Home" />
      <div className="row home-content">
        <div className="col-md intro">
          <Typist
            avgTypingDelay={23}
            stdTypingDelay={10}
            cursor={{ show: false }}
          >
            <h1 className="developer">
              Hi, I'm Luka,
              <br />
              web artisan.
            </h1>
            <h6 className="technologies">
              Full-stack developer / Angular / Node.js
            </h6>
          </Typist>
          <Fade delay={1800} when={componentMounted} duration={800} left>
            <Link className="work-btn mt-2" to="/work">
              My work
            </Link>
          </Fade>
        </div>
        <div className="col-md">
          <Fade delay={2300} when={componentMounted} duration={800} top>
            <h6 className="quote">
              "The only way to learn a new programming language is by writing
              programs in it."
            </h6>
            <span className="quotant">-Dennis Ritchie</span>
          </Fade>
        </div>
        <div className="col-12 pt-2">
          <div className="mx-auto logo pb-5 mb-5">
            <Roll delay={2900} when={componentMounted} duration={700} left>
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </Roll>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;
